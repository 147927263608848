import React,{useState}  from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
//import MyContext from '../context/MyContext'
import Link from '../components/gatsbylink'
import {Box,List,ListItem,Heading,Text,Button} from '@chakra-ui/react'
import { MyToast } from '../components/toast';
import { motion } from 'framer-motion';
import MeinSeo from '../components/seo'


// function BasicUsage({nr}) {
//   const { isOpen, onOpen, onClose } = useDisclosure()
//   const [size, setSize] = React.useState('full')

//   return (
//     <>
//       <Button onClick={onOpen}>Open Modal</Button>

//       <Modal bg="white"  size={size} isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Modal Title</ModalHeader>
         
//           <ModalBody>
//             {
//               nr==="05" && <Frenkel/>
//             }
//           </ModalBody>
//            <ModalFooter>
//             <Button colorScheme='blue' mr={3} onClick={onClose}>
//               Close
//             </Button>
//             <Button variant='ghost'>Secondary Action</Button>
//           </ModalFooter>
        
         
//         </ModalContent>
//       </Modal>
//     </>
//   )
// }
const bildname=[
  {'nr':'01','name':'Else Abraham, geb. Gerson','text1': "geboren 1899 in der Pliersgasse, verheiratet mit Leo Abraham in Altenkirchen",'text2': "ermordet 1941",'text3': "Ihrem Mann Leo Abraham, geflohen 1939, gelang es nicht, Frau und Kinder nach New York zu holen","url":"/gerson"},
  {'nr':'02','name':'u.a. die Kinder Lore und Bertel Abraham aus Altenkirchen','text1':' oft in Oberwesel bei Onkel und Tanten in der Pliersgasse 5 zu Besuch',"text2":"Sie werden zusammen mit der Mutter Else, geb. Marx, am 7.12.1941 deportiert und später für tot erklärt."},
  {'nr':'03','name':'Emma Frenkel, geb. Mayer',"text1": "geboren 1881 in Oberwesel",'text2': "deportiert 1942, verschollen im Ghetto Krasniczyn",'text3': "Ehefrau von Wilhem Frenkel, Kirchstraße","url":"/frenkel"},
  {'nr':'04','name':'Familie Abraham, Altenkirchen '},
  {'nr':'05','name':'Wilhelm Frenkel','text1': "geboren 1878 in Sien",'text2': "deportiert 1942, verschollen im Ghetto Krasniczyn",'text3': "Ehemann von Emma Frenkel geb. Mayer, Kirchstraße",'url':'/frenkel'},
  {'nr':'06','name':'Gustav und Henriette Gerson','text1': "Gustav Gerson: geboren 1865 in Perscheid",'text2': "verstorben  1940 an einem Herzschlag bei einer Kontrolle im Bahnhof, bestattet in Oberwesel",'text3': "Opfer von Rufmordartikeln 1935 im Nationalblatt und im Stürmer","url":"/gerson"},
  {'nr':'07','name':'Bertha Gerson, geb. Kahn','text1': "geboren 1875 in Hillesheim",'text2': "deportiert und ermordet 1942 in Theresienstadt",'text3': "Ehefrau von Theodor Gerson, Simmerner Straße","url":"/geschwister-gerson"},
  {'nr':'08','name':'Gerson & Trum',"text1":"Gustav Gerson mit Else und Rosalie Trum, Oberwesel","url":"/gerson"},
  {'nr':'09','name':'Max Gottschalk, Rotterdam', 'text1': "geboren 1898 in Niederzissen",'text2': "geflohen 1938 über Rotterdam nach New York",'text3': "Schwiegersohn Gustav Gersons, Kirchstraße","url":"/gottschalk"},
  {'nr':'10','name':'Rabbi Alfred Gottschalk','text1': "geboren 1930 in Koblenz",'text2': "geflohen 1939 nach New York",'text3': "aufgewachsen in der Kirchstraße, 1957 zum Rabbiner geweiht, von 1971 bis 1996 Präsident des Hebrew Union College Cincinnati und anschließend dessen Kanzler bis 2000","url":"/gottschalk"},
  {'nr':'11','name':'Herbert Lichtenstein','text1': "geboren 1920 in Oberwesel",'text2': "befreit 1945 aus Auschwitz",'text3': "Sohn von Wilheim Lichtenstein, Schaarplatz, zurückgekehrt 1945 nach Oberwesel, ausgewandert 1946 nach New York","url":"/lichtenstein"},
  {'nr':'12','name':'Jakob und Helene Lichtenstein',"text1":"Auf dem Schaarplatz 1 wohnten die Nachfahren des aus Laurenzberg bei Eschweiler zugewanderten Jakob Lichtenstein (*1827). ","url":"/lichtenstein"},
  {'nr':'13','name':'Schwiegertochter Ina',"text1":"Der Lehrer Heinrich und seine Frau  Ina Stein aus Grebenau konnten in die USA fliehen.","url":"/lichtenstein"},
  {'nr':'14','name':'Karl Lichtenstein','text1': "geboren 1857 in Glesch",'text2': "befreit 1945 aus Theresienstadt",'text3': "zurückgekehrt 1945 nach Oberwesel, ausgewandert 1946 nach New York","url":"/lichtenstein"},
  {'nr':'15','name':'Ruth Lichtenstein','text1': "geboren 1931 in Oberwesel",'text2': "befreit 1945 aus Theresienstadt",'text3': "Tochter von Selma und Theodor Lichtenstein, Schaarplatz, mit Mutter, Opa und Brüdern 1945 nach Oberwesel zurückgekehrt, zusammen ausgewandert 1946 nach New York","url":"/lichtenstein"},
  {'nr':'16','name':'Theodor Lichtenstein', 'text1': "geboren 1888 in Oberwesel",
  'text2': "deportiert 1942 nach Theresienstadt, ermordet 1944 in Auschwitz",
  'text3': "Sohn von Karl und Regina Lichtenstein, Ehemann von Selma geb. Strauß, Schaarplatz","url":"/lichtenstein"},
  {'nr':'17','name':'Heinrich und Ina Lichtenstein', 'text1': "Heinrich Lichtenstein: geboren 1889 in Oberwesel",'text2': "geflohen 1939 über England nach New York",'text3': "Sohn Karl Lichtensteins, Schaarplatz, Lehrer in Grebenau und in Offenbach, hat 1940 seine Erinnerungen aufgeschrieben","url":"/lichtenstein"},
  {'nr':'18','name':'Tilly Loeb geb. Mayer',"text1":"Tilly Loeb, Ehefrau von Walter Loeb, stirbt am 29.11.2009 in New York","url":"/mayer"},
  {'nr':'19','name':'Walter Loeb','text1': "geboren 1903 in Enkirch",'text2': "geflohen 1938 nach Cuba, von dort nach Miami",'text3': "Ehemann von Tilly Loeb, wohnhaft Simmerner Straße,stirbt am 01.06.1970 in New York", "url":"/mayer"},{'nr':'20','name':'Herta Marx', 'text1': "geboren 1905 in Oberwesel",'text2': "deportiert 1942 nach Theresienstadt und ermordet",'text3': "Schwester von Julius, Lina, Martha und Else Marx, Pliersgasse","url":"/marx"},
  {'nr':'21','name':'Lina Marx',"text1":"Am 30.04.1942 Paula und Julius Marx und seine Schwestern Lina und Herta werden vom Güterbahnhof Koblenz-Lützel ins Ghetto Krasniczyn transportiert.","url":"/marx"},
  {'nr':'22','name':'Ida Mayer, geb. Wolff','text1': "geboren 1895 in Planig",'text2': "deportiert 1942 nach Theresienstadt, ermordet",'text3': "Ehefrau von Albert Mayer, Schwägerin von Moritz und Jenny Mayer, Liebfrauenstraße","url":"/mayer-liebfrauenstr"},
  {'nr':'23','name':'Jacob Mayer','text1': "geboren 1879 in Oberwesel",'text2': "geflohen 1941 mit seiner Frau Clementine über Lissabon nach New York",'text3': "Ehemann von Clementine geb. Frenkel, Vater von Tilly, Erna und Herta Mayer, Rathausstraße","url":"/mayer-liebfrauenstr"},
  {'nr':'24','name':'Jenny Mayer','text1': "geboren 1879 in Oberwesel",'text2': "deportiert 1942 nach Theresienstadt, ermordet",'text3': "Unverheiratete Schwester von Moritz, Leo und Albert Mayer, Liebfrauenstraße","url":"/mayer-liebfrauenstr"},
  {'nr':'25','name':'Clementine Mayer, geb. Fränkl',"text1":"Jakob und Clementine Mayer gelingt mit der Hilfe des Besitzers der Schönburg, T. J. Oakley Rhinelander, die Flucht über Lissabon nach New York","url":"/mayer"},
  {'nr':'26','name':'Leo Mayer',"text1":"geb. am 30.10.1881 in Oberwesel","url":"/mayer-liebfrauenstr"},
  {'nr':'27','name':'Moritz Mayer', 'text1': "geboren 1876 in Oberwesel",'text2': "deportiert 1942 nach Theresienstadt, ermordet ",'text3': "Letzter Vorsitzender der Jüdischen Gemeinde","url":"/mayer-liebfrauenstr"},
  {'nr':'28','name':'Berta Salomon, geb. Gerson', 'text1': "geboren 1870 in Perscheid",
  'text2': "deportiert 1942 nach Theresienstadt, ermordet 1944",
  'text3': "Schwester von Gustav Gerson, Ehefrau von Isidor Salomon in Bad Neuenahr, zuletzt Simmerner Straße"},
  {'nr':'29','name':'Adolf Seligman','text1': "geboren 1869 in Oberwesel",'text2': "deportiert 1942 nach Theresienstadt, ermordet",'text3': "Bruder von Max, Karl und Siegmund Seligmann, sein Haus auf dem Heumarkt wurde zum Judenhaus erklärt, 1942 plündern Oberweseler Nazis den Weinkeller"},
  {'nr':'30','name':'Else Trum','text1': "geboren 1903 in Gau-Odernheim",'text2': "deportiert 1942 nach Theresienstadt, ermordet ",
  'text3': "Schwester von Erna Gottschalk, wohnhaft Kirchstraße, Simmerner Straße und Heumarkt","url":"/gottschalk"},
  {'nr':'31','name':'Rosalie Trum','text1': "geboren 1878 in Kobern",
  'text2': "geflohen 1940 nach Argentinien, von dort nach Amerika"}
]

const options ={
  buttons: {
    showDownloadButton: false
  },
  caption: {
    captionAlignment: 'start',
    captionContainerPadding: '20px',
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'transparent',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '0 1px',
    thumbnailsIconColor: '#ffffff',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: ['80px', '80px']
  }
}


  function Oberwesel({data}) {
    
  
    const [bindex,setBindex] = useState(-1)
    // const mydata = useOberwesel()
    // console.log(mydata)
    // Event handler utilizing useCallback ...
    // ... so that reference never changes.
    
    // Add event listener using our hook
    
const Texte = () => {
  return(
    <>
    <motion.div
initial={{
  position:'absolute',marginTop:0,zIndex:300,opacity:0,width:'100%',paddingBottom:"100px"
}}
animate={{ marginTop:'-108.5%',opacity:1}}
transition={{duration:.8}}
>

<Box>

{bildname[bindex]?.text1 &&

<Box  py="1"  px="3" bg='whiteAlpha.900'>

    <Text  py="0" fontWeight="400" fontSize="xs" textAlign="left">{bildname[bindex].text1} </Text>
 </Box>
 }
   {bildname[bindex]?.text2 &&
<Box py="1"  px="3" bg='whiteAlpha.900'>
 
    <Text py="0"   fontWeight="400" fontSize="xs" textAlign="left">{bildname[bindex].text2}</Text>
 </Box>
 }
 {bildname[bindex]?.text3 &&
<Box py="0"  px="3" bg='whiteAlpha.900'>
 
    <Text color="gray.900" py="0" fontWeight="400" fontSize="xs" textAlign="left">{bildname[bindex].text3}</Text>
 </Box>
 }
 {bildname[bindex]?.url &&
 <Box py="1"  px="3" bg='whiteAlpha.900'>

   <Link to={bildname[bindex]?.url}><Text color="blue" fontSize="sm"> &rarr; mehr Informationen</Text></Link>

 </Box>
  }
 
  </Box>
 
</motion.div>

</>
  )
}
    // useEffect(()=>{
     
    // },[info])

 
   
    return (
<Box py="8">
<MeinSeo title="Das Schicksal der jüdischer Familien  aus Oberwesel, Biografien und Fotos" description="Das Schicksal der jüdischer Familien  aus Oberwesel, Biografien und Fotos" />
      <div style={{maxWidth:1100}} className="MyComponent">

        <SRLWrapper options={options}>
        <Heading pb="0" mb="0" variant="Titel">Jüdische Menschen aus Oberwesel</Heading> 
              <Text pb="3" fontSize="lg">verschleppt, ermordert, verschollen, emigriert</Text> 
             <MyToast/>
          
             <Box my="8">
               <Text pb="2" fontWeight="600">Jüdische Familien aus Oberwesel</Text>
    <Link to="/mayer">
        <Text _hover={{color:'teal'}}>
        Jakob und Clementine Mayer und  Töchter   &rarr;
        </Text>

      </Link>
      <Link to="/mayer-oberstein">
        <Text _hover={{color:'teal'}}>
        Berthold Mayer und Emma Perlstein, geb. Mayer   &rarr;
        </Text>

      </Link>
      <Link to="/mayer-liebfrauenstr">
        <Text _hover={{color:'teal'}}>
        Geschwister Albert, Moritz, Thekla, Jenny und Leo Mayer   &rarr;
        </Text>

      </Link>
      <Link to="/marx">
        <Text _hover={{color:'teal'}}>
        Herta, Julius, Paula, Lina Marx  Julius, Paula Marx mit Ruth  Else Abraham, geb. Marx und ihre Töchter   &rarr;
        </Text>

      </Link>
     
      <Link to="/lichtenstein">
        <Text _hover={{color:'teal'}}>
        Karl und Regina Lichtenstein, Willy und Clementine Lichtenstein mit Herbert und Meta, Theodor und Selma Lichtenstein mit Karl-Heinz, Günther und Ruth   &rarr;
        </Text>

      </Link>
      <Link to="/gottschalk">
        <Text _hover={{color:'teal'}}>
      Erna, Max und Alfred Gottschalk   &rarr;  
        </Text>

      </Link>
      <Link to="/geschwister-gerson">
        <Text _hover={{color:'teal'}}>
          Geschwister Gerson – Geschwister Kahn   &rarr;  
        </Text>

      </Link>
      <Link to="/gerson">
        <Text _hover={{color:'teal'}}>
          Gustav und Henriette Gerson – Rosalie und Else Trum, Erna und Max Gottschalk und ihr Sohn Alfred  &rarr; 

      
        </Text>

      </Link>
      <Link to="/frenkel">
        <Text _hover={{color:'teal'}}>
        Wilhelm, Emma und Ruth Frenkel  &rarr;

      
        </Text>

      </Link>

     
     
      </Box>
             <List  display='flex' listStyle="none" flexWrap="wrap">

           
          
{data.images.edges.map((e,index) => {
 let mte=bildname[index]?.name
if(bildname[index]&&bildname[index].text1){
  mte +=", " +bildname[index].text1
}
if(bildname[index]&&bildname[index].text2){
  mte +=", "+bildname[index].text2
}

if(bildname[index]&&bildname[index].text3){
  mte +=", "+bildname[index].text3
}


  return (
     
    
    <ListItem key={index}  minWidth={['100%',"100%","48%","32%"]}     display='flex' width="33%" height="100%"> 
       <Box background="gray.800" m="1" p="1" zIndex="1"  top="99%" border="1px solid" borderColor="whiteAlpha.400" position="relative"   cursor="pointer" key={index} >
             
             <GatsbyImage

image={e.node.childImageSharp.gatsbyImageData}

alt={mte}

/>
<Box width="100%" height="8"  textAlign="center" display="inline-block" bottom="0" position="relative" zIndex="100" > 
<Text onClick={()=>setBindex(index)} display="inline-block" fontWeight="400" textAlign="center" color="white"  py="2" px="2"  fontSize="sm">
  
  {bildname[index]?.name!==undefined?bildname[index].name:""} 
 
  


</Text>
{bindex!==-1&& bindex===index && bildname[index].text1? <Button mt="2" py="1" color="black" bg="whiteAlpha.700"  height="5"  position="absolute" top="-33" left="43%" zIndex="840" onClick={()=>setBindex(-1)}>
      x</Button>
    :
    
      bildname[index].text1 && 
      <Button color="black" bg="whiteAlpha.700" left="43%" top="-33"  position="absolute" zIndex="840" rounded="50" height="5" mt="2" onClick={()=>setBindex(index)}> i</Button> 
    }
    
    
{bindex!==-1 && index===bindex &&
  <Texte ind={bindex} />
}

</Box>




</Box>
  
   
 </ListItem>

  
  )

})}
</List>

      
        </SRLWrapper>

      </div>
      <Box py="4">
      <Link to="/friedhof-oberwesel">
        <Text variant='solid' color="blue.400">
        &rarr; Fotografien vom Jüdischen Friedhof Oberwesel, 2018
        </Text>
      </Link>
      </Box>
      


      </Box>
    );
      }
     

  export const query = graphql`

  {

    images: allFile(
      filter: { relativeDirectory: { eq: "oberwesel" } }
      sort: { order: ASC, fields: base }
    ) {
      edges {
        node {
          base
          id
          relativePath
          childImageSharp {
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
              }
              aspectRatio: 1
              width: 900
              )
            }
         base
        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "oberwesel/thumbnails" } }
        sort: { order: ASC, fields: base }
  
      ) {
  
        edges {
  
          node {
  
            base
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: CONSTRAINED
  
                width: 120
  
                height: 120
  
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default Oberwesel;



